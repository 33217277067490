import { EXCHANGE_URLS } from "../../URLS";
import {
  fetchPredefineData,
  setSelectedMaterialImage,
  setSelectedOptions,
  setSelectedShapeImage,
} from "../../../redux/users/action";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import axios from "axios";
import aeroplane from "../../../Images/aeroplane.png";
import badgess from "../../../Images/badgess.png";
import moneyinhand from "../../../Images/moneyinhand.png";
import certifiedd from "../../../Images/certifiedd.png";
import pinkimg from "../../../Images/svgviewer-png-output (1).png";
import modimg from "../../../Images/modimg.PNG";
import wid from "../../../Images/svgviewer-png-output.png";
import met from "../../../Images/svgviewer-png-output (3).png";
import que from "../../../Images/svgviewer-png-output (7).png";
import circle from "../../../Images/Screenshot from 2024-07-25 17-29-15.png";
import car from "../../../Images/svgviewer-png-output (4).png";
import col from "../../../Images/svgviewer-png-output (5).png";
import cla from "../../../Images/svgviewer-png-output (6).png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../LoadingContext";
import deleteicon from "../../../Images/delete.PNG";
import ww from "../../../Images/ww.webp";
import Drawer from "react-modern-drawer";
import ROUND from "../../../Images/round-removebg-preview.png";
import one_four_k_white_gold from "../../../Images/fourone.png";
import one_four_k_yellow_gold from "../../../Images/fourtwo.png";
import one_four_k_pink_gold from "../../../Images/fourthree.png";
import one_eight_k_white_gold from "../../../Images/eightone.png";
import one_eight_k_yellow_gold from "../../../Images/eighttwo.png";
import one_eight_k_red from "../../../Images/eightthree.png";
import Platinum from "../../../Images/pt.png";
import Section3 from "./Section3";
import noimg from "../../../Images/eligantPacking.png";
import { toast } from "react-toastify";

const shapeList = [
  { value: "1x2", name: "1x2", imgUrl: ROUND },
  { value: "2x2", name: "2x2", imgUrl: ROUND },
  { value: "3.00mm (0.10ct)", name: "3.00mm (0.10ct)", imgUrl: ROUND },
  { value: "3.50mm (0.16ct)", name: "3.50mm (0.16ct)", imgUrl: ROUND },
  { value: "3.50mm (0.25ct)", name: "3.50mm (0.25ct)", imgUrl: ROUND },
  { value: "4x4", name: "4x4", imgUrl: ROUND },
  { value: "4.00mm (0.25ct)", name: "4.00mm (0.25ct)", imgUrl: ROUND },
  { value: "4.00mm (0.40ct)", name: "4.00mm (0.40ct)", imgUrl: ROUND },
  { value: "4.50mm", name: "4.50mm", imgUrl: ROUND },
  { value: "4.50mm (0.33ct)", name: "4.50mm (0.33ct)", imgUrl: ROUND },
  { value: "4.50mm (0.50ct)", name: "4.50mm (0.50ct)", imgUrl: ROUND },
  { value: "5x3", name: "5x3", imgUrl: ROUND },
  { value: "5x5", name: "5x5", imgUrl: ROUND },
  { value: "5.5x4", name: "5.5x4", imgUrl: ROUND },
  { value: "5.00mm", name: "5.00mm", imgUrl: ROUND },
  { value: "5 x 3.0MM", name: "5 x 3.0MM", imgUrl: ROUND },
  { value: "5.00mm (0.50ct)", name: "5.00mm (0.50ct)", imgUrl: ROUND },
  { value: "5.00mm (0.75ct)", name: "5.00mm (0.75ct)", imgUrl: ROUND },
  { value: "5.50mm (1.00ct)", name: "5.50mm (1.00ct)", imgUrl: ROUND },
  { value: "5.50mm (0.70ct)", name: "5.50mm (0.70ct)", imgUrl: ROUND },
  { value: "6x3 (0.30ct)", name: "6x3 (0.30ct)", imgUrl: ROUND },
  { value: "6x4 (0.50ct)", name: "6x4 (0.50ct)", imgUrl: ROUND },
  { value: "6x4 (0.60ct)", name: "6x4 (0.60ct)", imgUrl: ROUND },
  { value: "6.00mm (0.75ct)", name: "6.00mm (0.75ct)", imgUrl: ROUND },
  { value: "6.50mm (1.00ct)", name: "6.50mm (1.00ct)", imgUrl: ROUND },
  { value: "7 x 1.7MM", name: "7 x 1.7MM", imgUrl: ROUND },
  { value: "7 x 2.2MM", name: "7 x 2.2MM", imgUrl: ROUND },
  { value: "7x3.5 (0.40ct)", name: "7x3.5 (0.40ct)", imgUrl: ROUND },
  { value: "7x5 (0.75ct)", name: "7x5 (0.75ct)", imgUrl: ROUND },
  { value: "7.5x4.5", name: "7.5x4.5", imgUrl: ROUND },
];

const materialList = [
  {
    value: "14k White Gold",
    name: "White Gold",
    imgUrl: one_four_k_white_gold,
  },
  {
    value: "14k Yellow Gold",
    name: "Yellow Gold",
    imgUrl: one_four_k_yellow_gold,
  },
  { value: "14k Rose Gold", name: "Rose Gold", imgUrl: one_four_k_pink_gold },
  {
    value: "18k White Gold",
    name: "White Gold",
    imgUrl: one_eight_k_white_gold,
  },
  {
    value: "18k Yellow Gold",
    name: "Yellow Gold",
    imgUrl: one_eight_k_yellow_gold,
  },
  { value: "18k Rose Gold", name: "Rose Gold", imgUrl: one_eight_k_red },
  { value: "Platinum", name: "Platinum", imgUrl: Platinum },
];

export default function Section2() {
  const uniqueProduct = useSelector((state) => state.users.uniqueProduct);
  const [unique, setUnique] = useState(null);

  const preDataValueMaterial =
    unique?.variants?.edges?.[0]?.node?.selectedOptions?.find(
      (option) => option.name === "material"
    )?.value || "";

  const preDataValueShape =
    unique?.variants?.edges?.[0]?.node?.selectedOptions?.find(
      (option) => option.name === "Centre Stone Size"
    )?.value || "";

  const [selectedShape, setSelectedShape] = useState(preDataValueShape);
  const [selectedMaterial, setSelectedMaterial] =
    useState(preDataValueMaterial);

  const [material, setMaterial] = useState(
    preDataValueMaterial || selectedMaterial
  );
  const [color, setColor] = useState(preDataValueShape || selectedShape);

  const [preDefineData, setPreDefineData] = useState(null);
  const [selectedShapesImg, setSelectedShapesImg] = useState("");
  const [fingerSize, setFingerSize] = useState([]);
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [availableMaterials, setAvailableMaterials] = useState([]);
  const [availableShapes, setAvailableShapes] = useState([]);
  console.log(
    "preDataValueMaterial",
    unique,
    preDataValueMaterial,
    preDataValueShape
  );

  const fetchUniqueData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${EXCHANGE_URLS}/fetchpredefione?productId=${uniqueProduct}`
      );
      if (response.status === 200) {
        const { data } = response.data;
        console.log("kyahbae", data);
        const variants = data.variants;
        const materials = new Set();
        const shapes = new Set();

        // Extracting materials, carats, and shapes from the variants
        variants.edges.forEach((variant) => {
          variant.node.selectedOptions.forEach((option) => {
            if (option.name === "material") {
              materials.add(option.value);
            }
            if (option.name === "Centre Stone Size") {
              // Assuming this is for shapes
              shapes.add(option.value);
            }
          });
        });

        setAvailableMaterials([...materials]);
        setAvailableShapes([...shapes]);
        setUnique(response?.data?.data);
        console.log("last", response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching collections:", error);
    } finally {
      setLoading(false);
    }
  };

  // const fetchPreDefineApi = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get(
  //       `${EXCHANGE_URLS}/productPredefine?productId=${uniqueProduct}&carat=${selectedShape}&material=${selectedMaterial}`
  //     );
  //     if (response.status === 200) {
  //       const data = response?.data?.data;
  //       setPreDefineData(data);
  //       dispatch(fetchPredefineData(data));

  //       const materials = [];
  //       const shapes = [];

  //       data[0]?.selectedOptions?.forEach((option) => {
  //         if (option.name === "material" && !materials.includes(option.value)) {
  //           materials.push(option.value);
  //         }
  //         if (
  //           option.name === "Centre Stone Size" &&
  //           !shapes.includes(option.value)
  //         ) {
  //           shapes.push(option.value);
  //         }
  //       });

  //       if (materials.length > 0) {
  //         setSelectedMaterial(materials);
  //       }

  //       if (shapes.length > 0) {
  //         setSelectedShape(shapes);
  //       }
  //       console.log("last response check", data);
  //       dispatch(
  //         setSelectedOptions(
  //           preDefineData,
  //           uniqueProduct,
  //           material,
  //           color,
  //           selectedShape,
  //           selectedMaterial
  //         )
  //       );
  //       dispatch(setSelectedMaterialImage(imageUrl));
  //     }
  //   } catch (error) {
  //     console.error("Error fetching", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const fetchPreDefineApi = async () => {
    setLoading(true);
    try {
      // Construct the API URL dynamically based on the presence of `selectedShape` (carat)
      const baseUrl = `${EXCHANGE_URLS}/productPredefine?productId=${uniqueProduct}`;
      const caratQuery = selectedShape ? `&carat=${selectedShape}` : ""; // Add carat only if it exists
      const materialQuery = `&material=${selectedMaterial}`;
      const apiUrl = baseUrl + caratQuery + materialQuery;

      const response = await axios.get(apiUrl);

      if (response.status === 200) {
        const data = response?.data?.data;
        setPreDefineData(data); // Set the data to state
        dispatch(fetchPredefineData(data)); // Dispatch the data to Redux

        const materials = [];
        const shapes = [];

        // Extract materials and shapes from the response data
        data.forEach((item) => {
          item?.selectedOptions?.forEach((option) => {
            if (
              option.name === "material" &&
              !materials.includes(option.value)
            ) {
              materials.push(option.value);
            }
            if (
              option.name === "Centre Stone Size" &&
              !shapes.includes(option.value)
            ) {
              shapes.push(option.value);
            }
          });
        });

        // Update state with extracted values
        if (materials.length > 0) {
          setSelectedMaterial(materials[0]); // Set the first material as default
        }

        if (shapes.length > 0) {
          setSelectedShape(shapes[0]); // Set the first shape as default
        }

        console.log("Response processed successfully:", data);

        // Dispatch necessary data for Redux or other actions
        dispatch(
          setSelectedOptions(
            data,
            uniqueProduct,
            material,
            color,
            selectedShape,
            selectedMaterial
          )
        );
        dispatch(setSelectedMaterialImage(imageUrl));
      }
    } catch (error) {
      console.error("Error fetching predefine data:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleDrawer = () => {
    if (!selectedMaterial) {
      toast.error("Please select a material before opening the drawer.");
      return;
    }
    setIsOpen((prevState) => !prevState);
  };

  const handleCheckout = () => {
    if (!selectedMaterial) {
      toast.error("Please select a material before proceeding to checkout.");
      return;
    }

    navigate("/checkout", {
      state: {
        preDefineData: preDefineData,
        uniqueProduct: uniqueProduct,
        unicolor: selectedShapesImg,
        // unicarat: selectedCarat, ----------------------------------------------------------
        unimaterial: selectedMaterial,
        uniunique: preDefineData,
        imageUrl: imageUrl,
      },
    });
  };

  const handleShapeClick = (shapeName, shapeImageUrl, selectedShape) => {
    dispatch(setSelectedShapeImage(shapeImageUrl));
    setColor(selectedShape);
    setSelectedShape(shapeName, selectedShape);
    setSelectedShapesImg(shapeImageUrl);
  };

  // const handleCaratClick = (caratOption) => {
  //   setSelectedCarat(caratOption.type);
  //   setCarat(caratOption);
  // };

  const handleMaterialClick = (selectedMaterial) => {
    setSelectedMaterial(selectedMaterial);
    setMaterial(selectedMaterial);
  };

  const imageUrl =
    unique?.image?.edges?.node?.originalSrc ||
    preDefineData?.image?.originalSrc ||
    "No Product Variant Available";

  useEffect(() => {
    fetchUniqueData();
    fetchPreDefineApi();
  }, [selectedMaterial, selectedShape, dispatch, uniqueProduct]);

  const videoMetafield = "gid://shopify/Video/34989581926618";
  let videoUrl = null;

  if (videoMetafield) {
    const videoId = videoMetafield;
    videoUrl = `https://cdn.shopify.com/videos/${videoId}.mp4`;
  }

  useEffect(() => {
    if (preDataValueMaterial) {
      setSelectedMaterial(preDataValueMaterial);
      setMaterial(preDataValueMaterial);
    }

    if (preDataValueShape) {
      setSelectedShape(preDataValueShape);
      setColor(preDataValueShape);
    }
  }, [preDataValueMaterial, preDataValueShape]);
  return (
    <Root>
      <div className="main_wrapper">
        <div className="image_div">
          <ImageContainer>
            {imageUrl ? (
              <img src={imageUrl} title={unique?.title} alt="Product" />
            ) : (
              <img src={noimg} alt="img not available" />
            )}
          </ImageContainer>
        </div>
        <div className="des_div">
          <div className="title">
            <h2>{unique?.title}</h2>
            <h4>{preDefineData?.price}GBP</h4>
            <p>
              {unique?.description && unique.description}
              {!unique?.description && "No Description About Product"}
            </p>
          </div>

          {availableShapes.length > 0 && (
            <div className="ring_types mt-4">
              <h4>Center Stone Shape: {selectedShape}</h4>
              <div>
                {shapeList.map((shapeItem) =>
                  availableShapes.includes(shapeItem.value) ? (
                    <button
                      key={shapeItem.value}
                      className={`btn_shapes ${
                        selectedShape === shapeItem.value ? "selected" : ""
                      }`}
                      onClick={() => handleShapeClick(shapeItem.value)}
                    >
                      <img
                        className="img color_div"
                        src={shapeItem.imgUrl}
                        alt={shapeItem.value}
                      />
                      <p>{shapeItem.value}</p>
                    </button>
                  ) : null
                )}
              </div>
            </div>
          )}

          {availableMaterials.length > 0 && (
            <div className="ring_types mt-4">
              <h4>Material: {selectedMaterial}</h4>
              <div>
                {materialList.map((materialItem) =>
                  availableMaterials.includes(materialItem.value) ? (
                    <button
                      key={materialItem.value}
                      className={`btn_shapes ${
                        selectedMaterial === materialItem.value
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleMaterialClick(materialItem.value)}
                    >
                      <img
                        className="img color_div"
                        src={materialItem.imgUrl}
                        alt={materialItem.value}
                      />
                      <p>{materialItem.name}</p>
                    </button>
                  ) : null
                )}
              </div>
            </div>
          )}
          <div className="ring_size">
            <select
              value={fingerSize}
              onChange={(e) => setFingerSize(e.target.value)}
            >
              <option value="">Select Ring Size</option>
            </select>
          </div>

          <div className="product_btn">
            <button className="btn" onClick={toggleDrawer}>
              {selectedMaterial ? "Add to Cart" : "Select Ring Size"}
            </button>
            <Drawer
              open={isOpen}
              onClose={toggleDrawer}
              direction="right"
              className="custom-drawer"
              size="300px"
            >
              <div className="cart_heading">
                <h2>My Shopping Bag </h2>
              </div>

              <div className="prod_main_div">
                <div className="prod_div">
                  <div className="prod">
                    <div
                      className="bg-img"
                      style={{
                        height: "180px",
                        backgroundImage: `url(${imageUrl})`,
                      }}
                    >
                      <div className="dia_img">
                        {selectedShapesImg ? (
                          <img
                            src={selectedShapesImg}
                            title="Selected Shape Image"
                            alt="Selected Shape"
                          />
                        ) : (
                          <img
                            src={imageUrl}
                            title="Selected Shape Image"
                            alt="Selected Shape"
                          />
                        )}
                      </div>
                    </div>

                    <div className="prod_name">
                      <h3>
                        {unique?.title} -{preDefineData?.title}
                      </h3>
                    </div>

                    <div className="prod_spec">
                      <div className="icon_content">
                        {selectedShapesImg ? (
                          <img
                            src={selectedShapesImg}
                            title="Selected Shape Image"
                            alt="Selected Shape"
                          />
                        ) : (
                          <img
                            src={imageUrl}
                            title="Selected Shape Image"
                            alt="Selected Shape"
                          />
                        )}
                        <div className="content_head">
                          <h4> {unique?.title} </h4>
                          {selectedMaterial ? (
                            <p>{selectedMaterial?.name}</p>
                          ) : (
                            <p>Platinum</p>
                          )}
                        </div>
                      </div>
                      <div className="prod_price">
                        <h4>${preDefineData?.price}</h4>
                      </div>
                    </div>

                    <div className="prod_spec">
                      <div className="icon_content">
                        {selectedShapesImg ? (
                          <img
                            src={selectedShapesImg}
                            title="Selected Shape Image"
                            alt="Selected Shape"
                          />
                        ) : (
                          <img
                            src={imageUrl}
                            title="Selected Shape Image"
                            alt="Selected Shape"
                          />
                        )}

                        <div className="content_head">
                          <h4>{selectedShape} </h4>
                          <p> {preDefineData?.title}</p>
                        </div>
                      </div>
                      <div className="prod_price">
                        <h4>${preDefineData?.price}</h4>
                      </div>
                    </div>

                    <div className="price_div">
                      <p>
                        Total:{" "}
                        <span style={{ color: "#000000" }}>
                          <h4>${preDefineData?.price}</h4>
                        </span>
                      </p>
                      <div className="delete_icon">
                        <img src={deleteicon} alt="img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="total_price_div">
                <p>Total:</p>
                <h4>${preDefineData?.price}</h4>
              </div>

              <div className="but_div">
                <button onClick={handleCheckout}>
                  {selectedMaterial ? "Add to Cart" : "Select Ring Size"}
                </button>
              </div>
            </Drawer>
          </div>

          <div className="policy">
            <div className="policy_type">
              <img src={aeroplane} alt="aeroplane_images" />
              <p>
                Overnight <br />
                Shipping
              </p>
            </div>

            <div className="policy_type">
              <img
                src={badgess}
                alt="badgess_images"
                style={{ width: "50px", height: "50px" }}
              />
              <p>
                Lifetime <br />
                Warranty
              </p>
            </div>

            <div className="policy_type">
              <img src={moneyinhand} alt="moneyinhand_images" />
              <p>
                30 Days <br />
                Free Return
              </p>
            </div>

            <div className="policy_type">
              <img src={certifiedd} alt="certifiedd_images" />
              <p>
                Certificate
                <br />& Appraisal
              </p>
            </div>
          </div>

          <div className="setting_detail">
            <h4 className="seting_content">Setting Details</h4>

            <div className="setting_main_div">
              <div className="subfirst_detail">
                <div className="profile_div">
                  <div className="profile_cont">
                    <img src={wid} alt="pinkimg" />
                    <p>WIDTH</p>
                  </div>

                  <h4>1.8mm</h4>

                  <div className="img_div" style={{ visibility: "hidden" }}>
                    <img src={circle} alt="img" />
                  </div>
                  <p className="para">Measured at the base of the ring</p>
                </div>
              </div>

              <div className="subsecond_detail">
                <div className="profile_div">
                  <div className="profile_cont">
                    <img src={met} alt="pinkimg" />
                    <p>METAL</p>
                  </div>

                  <h4>14k White Gold</h4>

                  <div className="img_div">
                    <img src={circle} alt="img" />

                    <div className="quality_content">
                      <div className="color_content">
                        <div
                          className="color_box"
                          style={{ backgroundColor: "#F0E68C" }}
                        ></div>
                        <h5>58.5% Gold</h5>
                      </div>
                      <div className="color_content">
                        <div
                          className="color_box"
                          style={{ backgroundColor: "#BAC4C8" }}
                        ></div>
                        <h5>8.7% Zinc</h5>
                      </div>
                    </div>

                    <div className="quality_content">
                      <div className="color_content">
                        <div
                          className="color_box"
                          style={{ backgroundColor: "#B87333" }}
                        ></div>
                        <h5>25.4% Copper</h5>
                      </div>
                      <div className="color_content">
                        <div
                          className="color_box"
                          style={{ backgroundColor: "#BDBAAE" }}
                        ></div>
                        <h5>7.3% Nickel</h5>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="para">
                      The secret sauce that makes this piece.
                    </p>
                    <p className="para">
                      *All white gold pieces are Rhodium plated
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="setting_detail siz_div">
              <div className="profile_div">
                <div className="profile_cont">
                  <img src={pinkimg} alt="pinkimg" />
                  <p>{unique?.metafields?.edges?.[0]?.node?.key}</p>
                </div>
                <h4>{unique?.metafields?.edges?.[0]?.node?.value}</h4>
                <img src={modimg} alt="modimg" className="ring_img" />
                <p className="para">
                  {unique?.metafields?.edges?.[0]?.node?.namespace}
                </p>
              </div>
            </div>

            <h4 className="seting_content mt-5">Center Stone Details</h4>
            <div className="setting_Stone">
              <div className="sub_stone">
                <div className="cont_ques">
                  <div className="icon_cont">
                    <img src={car} alt="img" />
                    <p>{unique?.metafields?.edges?.[1]?.node?.key}</p>
                  </div>
                  <img src={que} alt="img" style={{ width: "16px" }} />
                </div>
                <h4>{unique?.metafields?.edges?.[1]?.node?.value}</h4>
                <div className="head_cont">
                  <p>{unique?.metafields?.edges?.[1]?.node?.namespace}</p>
                </div>
              </div>

              <div className="sub_stone">
                <div className="cont_ques">
                  <div className="icon_cont">
                    <img src={col} alt="img" />
                    <p>{unique?.metafields?.edges?.[2]?.node?.key}</p>
                  </div>
                  <img src={que} alt="img" style={{ width: "16px" }} />
                </div>

                <h4>{unique?.metafields?.edges?.[2]?.node?.value}</h4>
                <div className="head_cont">
                  <p>{unique?.metafields?.edges?.[2]?.node?.namespace}</p>
                </div>
              </div>

              <div className="sub_stone">
                <div className="cont_ques">
                  <div className="icon_cont">
                    <img src={cla} alt="img" />
                    <p>{unique?.metafields?.edges?.[3]?.node?.key}</p>
                  </div>
                  <img src={que} alt="img" style={{ width: "16px" }} />
                </div>

                <h4>{unique?.metafields?.edges?.[3]?.node?.value}</h4>
                <div className="head_cont">
                  <p>{unique?.metafields?.edges?.[3]?.node?.namespace}</p>
                </div>
              </div>

              <div className="sub_stone">
                <div className="cont_ques">
                  <div className="icon_cont">
                    <img src={cla} alt="img" />
                    <p>{unique?.metafields?.edges?.[6]?.node?.key}</p>
                  </div>
                  <img src={que} alt="img" style={{ width: "16px" }} />
                </div>

                <h4>{unique?.metafields?.edges?.[6]?.node?.value}</h4>
                <div className="head_cont">
                  <p>{unique?.metafields?.edges?.[6]?.node?.namespace}</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Section3 />
          </div>

          <div className="appointment">
            <h5>Virtual Appointment</h5>
            <p>
              <strong>See jewelry</strong> up close with a personal appointment.
              Explore engagement rings, diamonds, and fine jewelry in person
              through your device.
            </p>
          </div>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  padding: 20px;
  margin: 20px 0px;
  position: relative;

  .main_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;
    position: relative;

    .image_div {
      flex: 1;
      top: 20px;
      border: 1px solid #d3d3d3;
      padding: 20px 20px 0px 20px;
      height: 600px;
      border-radius: 20px;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 90%;
      }

      button.button {
        background-color: white;
        border: none;
        border-top: 1px solid #d3d3d3;
        border-radius: 0 0 20px 20px;

        svg {
          width: 18px;
          margin-right: 5px;
        }
        span {
          font-size: 13px;
        }
      }
    }

    .des_div {
      flex: 1;
      overflow: auto;
      position: relative;
      padding: 10px;
      .ring_size {
        display: flex;
        justify-content: start;
        margin-top: 20px;
        select {
          font-size: 14px;
          font-weight: 400;
          border-radius: 6px;
          background-color: #fff;
          border: 1px solid #e0e0e0;
          padding: 0.75rem 3rem 0.75rem 0.75rem;
          cursor: pointer;
          transition: 0.5s;
          &:hover {
            box-shadow: 0 0 5px #cacaca;
          }
        }
      }
      .title {
        h2 {
          font-size: 23px;
          font-weight: 600;
          line-height: 1.2;
        }

        h4 {
          font-size: 21px;
          color: #666666;
          font-weight: 500;
          line-height: 1.25;
        }
        p {
          font-size: 13px;
          font-weight: 400;
          color: #666666;
        }
      }

      .prod_spec {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 10px;
        .carattt {
          gap: 10px;
          display: flex;
          flex-wrap: wrap;
        }
        h4 {
          margin-bottom: 0;
          font-size: 14px;
          color: #000000;
          font-weight: 700;
          margin-bottom: 3px;
        }
        .spec {
          display: flex;
          flex-direction: column;
          padding: 10px;
          width: 55px;
          height: 50px;
          justify-content: center;
          border-radius: 11px;
          background: transparent;
          align-items: center;
          cursor: pointer;
          border: 1px solid #bbb9b9;
          &.selected {
            border: 2px solid #000;
          }

          p {
            color: #666666;
            font-size: 11px;
            margin-bottom: 0;
            line-height: 1.2;
          }
        }
      }
      .ring_types {
        display: flex;
        flex-direction: column;

        h4 {
          font-size: 14px;
          color: #000000;
          font-weight: 700;
          margin-bottom: 3px;
        }
        > div {
          display: flex;
          overflow-x: auto;
          .btn_shapes {
            width: 80px !important;
            border: 2px solid transparent;
            background: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px 5px;
            font-size: 12px;
            line-height: 25px;
            font-weight: 500;
            p {
              text-transform: capitalize;
              font-size: 10px;
              font-weight: 600;
              margin: 0;
            }
            .img {
              width: 40px;
              height: 40px;
            }
            &.selected {
              border-bottom: 3px solid black;
            }

            &:hover {
              background-color: rgba(247, 247, 247);
            }
          }
        }
      }
      .product_btn {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 50px;
        .btn {
          background-color: rgba(0, 0, 0);
          color: white;
          font-size: 17px;
          padding: 16px 0;
          font-weight: 600;
          border-radius: 50px;
          border: 1px solid transparent;
        }
      }
    }

    .siz_div {
      width: 35%;
    }
    .setting_main_div {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      .subfirst_detail {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 35%;

        .profile_div {
          background: #fff;
          border: 1px solid #e0e0e0;
          border-radius: 12px;
          padding: 10px;
          display: flex;
          gap: 8px;
          flex-direction: column;
          .profile_cont {
            display: flex;
            align-items: center;
            p {
              font-size: 12px;
              color: #bbb9b9;
              font-weight: 600;
              text-transform: uppercase;
              margin-bottom: 0;
            }
          }
          h4 {
            color: #000000;
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 0;
          }
          .ring_img {
            width: 70px;
          }
          .img_div {
            display: flex;
            gap: 15px;
            visibility: hidden;
          }

          .para {
            font-size: 12px;
            font-weight: 500;
            color: #666666;
            margin-bottom: 0;
          }
        }
      }

      .subsecond_detail {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 60%;

        .profile_div {
          background: #fff;
          border: 1px solid #e0e0e0;
          border-radius: 12px;
          padding: 10px;
          display: flex;
          gap: 8px;
          flex-direction: column;

          .profile_cont {
            display: flex;
            align-items: center;
            p {
              font-size: 12px;
              color: #bbb9b9;
              font-weight: 600;
              text-transform: uppercase;
              margin-bottom: 0;
            }
          }

          h4 {
            font-size: 24px;
            color: #000000;
            font-weight: 700;
            margin-bottom: 0%;
          }

          .ring_img {
            width: 70px;
          }

          .img_div {
            display: flex;
            gap: 15px;
            .quality_content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 8px;
              .color_content {
                display: flex;
                gap: 10px;
                align-items: center;
                .color_box {
                  height: 16px;
                  width: 16px;
                  border-radius: 30px;
                }
                h5 {
                  font-size: 11px;
                  font-weight: 500;
                  margin-bottom: 0;
                }
              }
            }
          }

          .para {
            font-size: 12px;
            font-weight: 500;
            color: #666666;
            margin-bottom: 0;
          }
        }
      }
    }

    .policy {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      .policy_type {
        flex: 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 35px;
          height: 35px;
        }
        p {
          font-size: 14px;
          color: #333;
          margin-top: 10px;
          text-align: center;
          line-height: 16px;
        }
      }
    }

    .seting_content {
      font-size: 16px;
      color: #000000;
      font-weight: 700;
      margin: 20px 0;
    }

    .setting_detail {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: 10px;
      h4 {
        font-size: 16px;
        color: #000000;
        font-weight: 700;
      }
      .profile_div {
        background: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 12px;
        padding: 10px;
        display: flex;
        gap: 8px;
        flex-direction: column;
        .profile_cont {
          display: flex;
          gap: 2px;
          align-items: center;
          p {
            font-size: 12px;
            color: #bbb9b9;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 0;
          }
        }
        h4 {
          font-size: 24px;
        }
        .ring_img {
          width: 70px;
        }
        .para {
          font-size: 12px;
          font-weight: 500;
          color: #666666;
          margin-bottom: 0;
        }
      }
    }

    .setting_Stone {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      .sub_stone {
        width: 47%;
        border: 1px solid #e0e0e0;
        border-radius: 12px;
        padding: 10px;
        height: 160px;
        background: #fff;
        .cont_ques {
          display: flex;
          justify-content: space-between;
          .icon_cont {
            display: flex;
            align-items: center;
            p {
              font-size: 0.7em;
              font-weight: 600;
              text-transform: uppercase;
              line-height: 1;
              color: #000;
              opacity: 0.3;
              margin-bottom: 0;
              gap: 0px;
            }
          }
        }

        h4 {
          font-size: 24px;
          color: #000000;
          font-weight: 700;
          margin-top: 10px;
          margin-left: 5px;
        }

        .head_cont {
          margin-top: 40px;
          p {
            font-size: 12px;
            font-weight: 500;
            color: #666666;
            margin-bottom: 0;
          }
        }
      }
    }

    .appointment {
      background-color: rgba(247, 247, 247);
      padding: 20px;
      border-radius: 20px;
      margin-top: 25px;

      h5 {
        font-size: 1rem;
        font-weight: 600;
      }
      p {
        font-size: 13px;
        margin-bottom: 0;
      }
      button {
        font-size: 0.875rem;
        letter-spacing: 0;
        line-height: 1.2;
        font-weight: 400;
        text-decoration: underline;
        border: 1px solid transparent;
        margin-top: 10px;
        padding: 0;
        color: #000000;
      }
    }
  }
  .custom-drawer {
    z-index: 11111111 !important;
  }
  .cart_heading {
    padding: 16px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px -1px rgba(0, 0, 0, 0.1);

    h2 {
      font-size: 16px;
      color: #000000;
      font-weight: 400;
    }
  }
  .prod_main_div {
    width: 100%;
    max-height: 420px;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    .prod_div {
      padding: 10px 16px;
      .prod {
        padding: 12px;
        background-color: #f7f7f7;
        border-radius: 1.25rem;
        display: flex;
        flex-direction: column;
        gap: 7px;
        .bg-img {
          height: 180px;
          background-image: url(${ww});
          background-size: 100%;
          background-repeat: no-repeat;
          .dia_img {
            display: flex;
            align-items: flex-end;
            height: 100%;
            width: 100%;
            justify-content: flex-end;

            img {
              width: 20%;
              height: 20%;
              object-fit: contain;
            }
          }
        }
        .prod_name {
          h3 {
            font-size: 13px;
            color: #000000;
            font-weight: 400;
          }
        }
        .prod_spec {
          display: flex;
          justify-content: space-between;
          padding-bottom: 10px;
          border-bottom: 1px solid #ededed;
          .icon_content {
            display: flex;
            align-items: center;
            img {
              width: 40px;
              height: 40px;
              mix-blend-mode: multiply;
            }
            .content_head {
              display: flex;
              flex-direction: column;
              h4 {
                font-size: 12px;
                color: #000000;
                margin-bottom: 0;
                font-weight: 500;
              }
              p {
                font-size: 12px;
                color: #808080;
                margin-bottom: 0;
              }
            }
          }
          .prod_price {
            h4 {
              font-weight: 500;
              font-size: 14px;
              margin-bottom: 0;
            }
          }
        }
        .price_div {
          display: flex;
          justify-content: space-between;
          p {
            font-size: 16px;
            color: rgba(102, 102, 102);
            font-weight: 500;
          }

          .delete_icon {
            img {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .element-with-scroll::-webkit-scrollbar {
    display: none;
  }

  .total_price_div {
    padding: 0px 16px;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 18px;
      color: #666666;
    }

    h4 {
      font-weight: 500;
      font-size: 18px;
      color: #000000;
    }
  }
  .but_div {
    padding: 16px;
    margin-bottom: 20px;
    button {
      color: rgba(255, 255, 255);
      font-weight: 600;
      font-size: 1rem;
      text-align: center;
      padding: 1rem 2rem;
      background-color: #000000;
      border: transparent;
      border-radius: 30px;
      width: 100%;
    }
  }

  @media (max-width: 567px) {
    padding: 10px 0px;
    .main_wrapper {
      gap: 0px;
      display: unset;
    }

    .main_wrapper .image_div {
      /* width: 100%; */
      height: unset;
      padding: 5px;
      margin: 10px;
      img {
        width: 90vw;
      }
    }
    .main_wrapper .des_div .prod_spec {
      width: 90vw;
      .carattt {
        width: 100%;
        gap: 10px;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding-bottom: 10px;
      }
    }
    .main_wrapper .ring_size select {
      width: 100%;
    }
    .main_wrapper .des_div {
      width: 100%;
      margin-top: 20px;
      padding: 5px;
    }
    .main_wrapper .setting_detail .diamond_info .setting_div {
      flex: 1;
    }
    .main_wrapper .des_div .prod_spec .spec {
      padding: 0px 14px;
    }
    .main_wrapper .des_div .prod_spec {
      gap: 20px;
    }
    .main_wrapper .des_div .title h2,
    .main_wrapper .des_div .title h4 {
      font-size: 18px;
    }

    .main_wrapper .setting_main_div .subfirst_detail .profile_div .para {
      margin-bottom: 15px;
    }
    .main_wrapper .setting_main_div .subfirst_detail {
      width: 100%;
    }
    .main_wrapper .setting_main_div .subsecond_detail {
      width: 100%;
    }
    .main_wrapper .setting_detail {
      width: 100%;
    }
    .main_wrapper .setting_Stone .sub_stone .head_cont {
      margin-top: 25px;
    }
    .siz_div {
      width: 100%;
    }

    .main_wrapper .policy .policy_type p {
      font-size: 11px;
    }
  }

  /* @media (min-width: 430px) and (max-width: 932px) {
    display:unset;
  } */

  @media (min-width: 567px) and (max-width: 992px) {
    .main_wrapper {
      gap: 0px;
    }

    .main_wrapper .image_div {
      width: 100%;
      height: unset;
      margin: 20px;
      flex: unset;
    }
    .main_wrapper .des_div {
      width: 100%;
      margin-top: 20px;
      padding: 5px;
    }
    .main_wrapper .des_div h2 {
      font-size: 18px;
    }
    .main_wrapper .setting_detail .diamond_info .setting_div {
      flex: 1;
    }
  }
`;
const ImageContainer = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
  }

  img {
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
      height: 100%;
    }
  }
`;
